import React, { useEffect, useState } from "react";
import { MDBCol, MDBNavItem, MDBTypography, MDBBox, MDBRow } from "mdbreact";
import LightingFixtures from "./LightingFixtures";

function ProductsSubmenu({ menuItems }) {
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(
    () =>
      menuItems?.length && !activeCategory && setActiveCategory(menuItems[0]),
    [menuItems, activeCategory]
  );

  return (
    <>
      <MDBCol lg="3" className={"pages-part first"}>
        <ul>
          {menuItems?.map((menuItem) => (
            <MDBNavItem
              onMouseOver={() =>
                activeCategory?.id !== menuItem?.id &&
                setActiveCategory(menuItem)
              }
              className={`category-title ${
                activeCategory?.id === menuItem?.id && "active"
              }`}
              key={`${menuItem.url_key}-${menuItem.id}`}
            >
              {menuItem.name}
            </MDBNavItem>
          ))}
        </ul>
      </MDBCol>
      <MDBCol lg="6" className="pages-part">
        {/* <MDBBox className="submenu-title">
          <MDBTypography tag="h6" variant="h6">
            {activeCategory?.name}
          </MDBTypography>
        </MDBBox> */}
        {activeCategory?.name === "Lighting Fixtures" ? (
          <LightingFixtures menuItems={activeCategory?.children} />
        ) : (
          <MDBRow>
            <MDBCol md="12">
              <ul className="children-list">
                {activeCategory?.children.map((item) => {
                  return (
                    <MDBNavItem key={`first-column-${item.url_key}-${item.id}`}>
                      <a
                        href={
                          item.id === 64 || item.id === 65 || item.id === 122
                            ? `/${item.url_key}`
                            : `/${item.url_path}`
                        }
                      >
                        {item.name}
                      </a>
                    </MDBNavItem>
                  );
                })}
              </ul>
            </MDBCol>
          </MDBRow>
        )}
      </MDBCol>
      <MDBCol lg="3" className="d-flex align-items-center">
        <img
          className="mx-auto"
          src={activeCategory?.image}
          alt={activeCategory?.name}
        />
      </MDBCol>
    </>
  );
}

export default ProductsSubmenu;
