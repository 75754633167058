import React, { useMemo, useState } from "react";

import { MDBNavItem, MDBNavLink } from "mdbreact";
import Submenu from "app/layout/menu/submenu";
import SubmenuLightningFixture from "./submenu/submenuLightningFixture";

const Menu = ({ menuItems }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [activeCategory, setActiveCategory] = useState(true);

  menuItems.children.sort((a, b) => a.position - b.position);

  let _menuItems = menuItems.children.filter((x) => x.include_in_menu);
  _menuItems.sort((a, b) => a.position - b.position);

  const modifiedMenuItems = useMemo(() => {
    const productsItems = [..._menuItems]?.filter(
      (el) => el?.products?.total_count
    );

    return [
      {
        name: "Products",
        children: productsItems,
        is_clickable: null,
        include_in_menu: 1,
        is_anchor: 0,
        url_path: "products",
      },
    ].concat([..._menuItems]?.filter((el) => !el?.products?.total_count));
  }, [_menuItems]);
  console.log(modifiedMenuItems);
  if (!menuItems) return null;
  return (
    <ul className="d-flex align-items-center menu-font-family">
      {modifiedMenuItems.map((item) => {
        return (
          <MDBNavItem
            key={`menu-item-desktop-${item.id}`}
            onMouseEnter={() => setShowSubmenu(true)}
            onMouseLeave={() => setShowSubmenu(false)}
            onClick={(e) => {
              setShowSubmenu(false);
            }}
          >
            {/* <MDBNavLink
              to={parseInt(item.is_anchor) ? `/${item.url_path}` : `#`}
              onClick={(e) => {
                if (item.is_clickable === 0) {
                  e.preventDefault();
                }
              }}
              onMouseEnter={() => {
                setActiveCategory({ ...item });
              }}
            >
              {item.name}
            </MDBNavLink> */}
            <a
              href={parseInt(item.is_anchor) ? `/${item.url_path}` : `#`}
              onClick={(e) => {
                if (item.is_clickable === 0) {
                  e.preventDefault();
                }
              }}
              onMouseEnter={() => {
                setActiveCategory({ ...item });
              }}
            >
              {item.name}
            </a>
            {activeCategory.id !== 3 &&
              activeCategory.id === item.id &&
              activeCategory.children?.length > 0 &&
              showSubmenu && (
                <Submenu
                  menuItems={[...activeCategory.children]}
                  activeCategory={activeCategory}
                />
              )}
            {activeCategory.id === 3 &&
              activeCategory.id === item.id &&
              activeCategory.children?.length > 0 &&
              showSubmenu && (
                <SubmenuLightningFixture
                  menuItems={[...activeCategory.children]}
                  activeCategory={activeCategory}
                />
              )}
          </MDBNavItem>
        );
      })}
    </ul>
  );
};

export default Menu;
