import React from "react";
import Config from "config/themeConfiguration";
import {
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBContainer,
  MDBBox,
  MDBCol,
  MDBTypography,
} from "mdbreact";
import ProductsSubmenu from "./ProductsSubmenu";
const defaultHero =
  "https://primalighting.joladev.com/wp-content/uploads/2015/12/g-40-1024x576.jpg";

const Submenu = (props) => {
  const { menuItems, activeCategory } = props;
  menuItems.sort((a, b) => a.position - b.position);
  const firstColumn = menuItems.slice(0, menuItems.length / 2);
  const secondColumn = menuItems.slice(menuItems.length / 2, menuItems.length);
  let heroImage = activeCategory.additional_image
    ? `${Config.magento_url}media/catalog/category/${activeCategory.additional_image}`
    : defaultHero;
  return (
    <MDBBox className="submenu">
      <MDBContainer fluid>
        <MDBRow>
          {activeCategory.name === "Products" ? (
            <ProductsSubmenu menuItems={menuItems} />
          ) : (
            <>
              <MDBCol lg="5" className="pages-part">
                <MDBBox className="submenu-title">
                  <MDBTypography tag="h6" variant="h6">
                    {activeCategory.name}
                  </MDBTypography>
                </MDBBox>
                <MDBRow>
                  {menuItems.length < 4 ? (
                    <MDBCol md="12">
                      <ul>
                        {menuItems.map((item) => {
                          return (
                            <MDBNavItem
                              key={`first-column-${item.url_key}-${item.id}`}
                            >
                              <a
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          );
                        })}
                      </ul>
                    </MDBCol>
                  ) : (
                    <>
                      <MDBCol md="6">
                        <ul>
                          {firstColumn.map((item) => {
                            return (
                              <MDBNavItem
                                key={`first-column-${item.url_key}-${item.id}`}
                              >
                                {/* <MDBNavLink
                              to={
                                item.id === 64 ||
                                item.id === 65 ||
                                item.id === 122
                                  ? `/${item.url_key}`
                                  : `/${item.url_path}`
                              }
                            >

                              {item.name}
                            </MDBNavLink> */}
                                <a
                                  href={
                                    item.id === 64 ||
                                    item.id === 65 ||
                                    item.id === 122
                                      ? `/${item.url_key}`
                                      : `/${item.url_path}`
                                  }
                                >
                                  {item.name}
                                </a>
                              </MDBNavItem>
                            );
                          })}
                        </ul>
                      </MDBCol>
                      <MDBCol md="6">
                        <ul>
                          {secondColumn.map((item) => (
                            <MDBNavItem
                              key={`second-column-${item.url_key}-${item.id}`}
                            >
                              {/* <MDBNavLink
                            to={
                              item.id === 64 ||
                              item.id === 65 ||
                              item.id === 122
                                ? `/${item.url_key}`
                                : `/${item.url_path}`
                            }
                          >
                            {item.name}
                          </MDBNavLink> */}
                              <a
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          ))}
                        </ul>
                      </MDBCol>
                    </>
                  )}
                </MDBRow>
                <MDBBox className="view-all-box d-flex">
                  {/* <a href="#">view all</a> */}
                </MDBBox>
              </MDBCol>
              <MDBCol lg="7">
                <MDBBox className="submenu-right">
                  <MDBRow>
                    <MDBCol md="4">
                      <MDBBox className="product-image d-flex align-items-center justify-content-center mt-0 pt-0">
                        {activeCategory.image ? (
                          <img
                            src={`${activeCategory.image}`}
                            alt="thumbnailImage"
                            className="img-fluid"
                          />
                        ) : (
                          <></>
                        )}
                      </MDBBox>
                    </MDBCol>
                    <MDBCol md="8" className="p-0">
                      <MDBBox
                        className="product-back"
                        style={{
                          background: `url(${heroImage})`,
                        }}
                      ></MDBBox>
                    </MDBCol>
                  </MDBRow>
                </MDBBox>
              </MDBCol>
            </>
          )}
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default Submenu;
