import React from "react";
import { MDBRow, MDBCol, MDBNavItem } from "mdbreact";

function LightingFixtures({ menuItems }) {
  return (
    <MDBRow>
      <MDBCol md="6">
        <ul>
          <MDBNavItem
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              fontWeight: 900,
              fontSize: "14px",
              color: "#49494f",
            }}
          >
            Pendants
          </MDBNavItem>
          {menuItems.map((item, index) => {
            if (
              item.name === "Interchangeable Pendants" ||
              item.name === "RLM Pendants" ||
              item.name === "Imported Pendant Lighting" ||
              item.name === "Made in USA Pendants"
            ) {
              return (
                <MDBNavItem
                  style={{ marginLeft: "15px" }}
                  key={`first-column-${item.url_key}-${item.id}`}
                >
                  {/* 
                            <MDBNavLink  to={
                              item.id === 64 ||
                              item.id === 65 ||
                              item.id === 122
                                  ? `/${item.url_key}`
                                  : `/${item.url_path}`
                            }>{item.name}</MDBNavLink> */}

                  <a
                    href={
                      item.id === 64 || item.id === 65 || item.id === 122
                        ? `/${item.url_key}`
                        : `/${item.url_path}`
                    }
                  >
                    {item.name}
                  </a>
                </MDBNavItem>
              );
            }
          })}
        </ul>
      </MDBCol>
      <MDBCol md="6">
        <ul>
          <MDBNavItem
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              fontWeight: 900,
              fontSize: "14px",
              color: "#49494f",
            }}
          >
            Wall Mounted
          </MDBNavItem>
          {menuItems.map((item, index) => {
            // console.log("menu items id", item.id );
            // console.log("menu items name",  item.name );
            if (
              item.name === "ADA Wall Sconce" ||
              item.name === "Non ADA Wall Lights" ||
              item.name === "Bathroom Vanities"
            ) {
              return (
                <MDBNavItem
                  style={{ marginLeft: "15px" }}
                  key={`first-column-${item.url_key}-${item.id}`}
                >
                  {/* <MDBNavLink  to={
                              item.id === 64 ||
                              item.id === 65 ||
                              item.id === 122
                                  ? `/${item.url_key}`
                                  : `/${item.url_path}`
                            }>{item.name}</MDBNavLink> */}
                  <a
                    href={
                      item.id === 64 || item.id === 65 || item.id === 122
                        ? `/${item.url_key}`
                        : `/${item.url_path}`
                    }
                  >
                    {item.name}
                  </a>
                </MDBNavItem>
              );
            }
          })}
        </ul>
      </MDBCol>
      <MDBCol md="6">
        <ul>
          <MDBNavItem
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              fontWeight: 900,
              fontSize: "14px",
              color: "#49494f",
            }}
          >
            Ceiling Mounted
          </MDBNavItem>
          {menuItems.map((item, index) => {
            // console.log("menu items id", item.id );
            // console.log("menu items name",  item.name );
            if (
              item.name === 'Less Than 12" Diameter' ||
              item.name === 'Between 12"-18" Diameter' ||
              item.name === 'Greater Than 18" Diameter'
            ) {
              return (
                <MDBNavItem
                  style={{ marginLeft: "15px" }}
                  key={`first-column-${item.url_key}-${item.id}`}
                >
                  {/* <MDBNavLink  to={
                              item.id === 64 ||
                              item.id === 65 ||
                              item.id === 122
                                  ? `/${item.url_key}`
                                  : `/${item.url_path}`
                            }>{item.name}</MDBNavLink> */}
                  <a
                    href={
                      item.id === 64 || item.id === 65 || item.id === 122
                        ? `/${item.url_key}`
                        : `/${item.url_path}`
                    }
                  >
                    {item.name}
                  </a>
                </MDBNavItem>
              );
            }
          })}
        </ul>
      </MDBCol>
      <MDBCol>
        <ul>
          <MDBNavItem
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              fontWeight: 900,
              fontSize: "14px",
              color: "#49494f",
            }}
          ></MDBNavItem>
          {menuItems.map((item, index) => {
            // console.log("menu items id", item.id );
            // console.log("menu items name",  item.name );
            if (
              item.name === "Recessed Lights" ||
              item.name === "Lighted Mirrors" ||
              item.name === "Linear LED Fixtures" ||
              item.name === "Interchangeable Track Heads" ||
              item.name === "Display / Picture Lights"
            ) {
              return (
                <MDBNavItem
                  style={{ fontWeight: 900, color: "#49494f" }}
                  key={`first-column-${item.url_key}-${item.id}`}
                >
                  {/* <MDBNavLink style={{fontWeight:900, color:"#49494f",  fontSize:"13px"}} to={
                            item.id === 64 ||
                            item.id === 65 ||
                            item.id === 122
                                ? `/${item.url_key}`
                                : `/${item.url_path}`
                          }>{item.name}</MDBNavLink> */}
                  <a
                    style={{
                      fontWeight: 900,
                      color: "#49494f",
                      fontSize: "13px",
                    }}
                    href={
                      item.id === 64 || item.id === 65 || item.id === 122
                        ? `/${item.url_key}`
                        : `/${item.url_path}`
                    }
                  >
                    {item.name}
                  </a>
                </MDBNavItem>
              );
            }
          })}
        </ul>
      </MDBCol>
    </MDBRow>
  );
}

export default LightingFixtures;
